<template>
    <el-row>
        <div class="item">
              <!-- 是否退货 0否 1是 -->
             <div class="itemLabel" v-if="goodsInfo.isRefund==1">
              </div>
             <p  class="itemLabelP" v-if="goodsInfo.isRefund==1">
               <span>已退货</span>    
               <span>退货数量:{{goodsInfo.refundCount}}{{goodsInfo.unit}}</span>   
               <span>退款金额:{{goodsInfo.refundAmount}}</span> 
            </p>
             <el-col :span="9"><ShopImg :msg="goodsInfo"></ShopImg></el-col>
             <el-col :span="4" class="text-center">{{goodsInfo.originalPrice}}</el-col>
             <el-col :span="3" class="text-center">{{goodsInfo.goodsCount}}</el-col>
             <!-- {{goodsInfo.payablePrice}} -->
             <el-col :span="3" class="text-center ">{{total}}</el-col>
             <el-col :span="3" class="text-center total">{{goodsInfo.payablePrice}}</el-col>
             <el-col   v-if="goodsInfo.isRefund!=1" :span="3"  class="text-center msgBox" @click.native="downFun(goodsInfo)">下载药检报告</el-col>
             <el-col   v-if="goodsInfo.isRefund==1" :span="3"  class="text-center msgBox">无</el-col>
        </div>
    </el-row>
</template>
<script>
import {GetOrderDetailReport} from "api/order.js";
const ShopImg = ()=>import("components/common/ShopImg.vue");
export default {
  name:"OrderShopInfo",
  data(){
      return{
          total:0
      }
  },
  props:{
    goodsInfo:{
      type:Object
    }
  },
 created(){
   this.total=(Number(this.goodsInfo.originalPrice)*Number(this.goodsInfo.goodsCount)).toFixed(2)
 },
  methods: {
    // 下载商品药检报告
   downFun(Goods){
      GetOrderDetailReport(Goods.id,Goods.goodNum).then(res=>{
            if (res.data.type == "application/json") {
              this.$message.error("暂无药检报告");
            } else {
               // 下载pdf
                this.pdfUrl = window.URL.createObjectURL(new Blob([res.data], { type: `application/pdf` }));
                const fname = Goods.goodsName+'药检报告'; // 下载文件的名字
                const link = document.createElement('a');
                link.href = this.pdfUrl;
                link.setAttribute('download', fname);
                document.body.appendChild(link);
                link.click();
            }
      })
   }
  },
  components:{
    ShopImg  
  },
  
};
</script>



<style lang="less" scoped>
  @import '~style/index.less';
 .item {
    display: flex;
    align-items: center;
    padding: 15px 0px;
    border-left:1px solid #F0F0F0;
    border-bottom: 1px solid #F0F0F0;
    border-right:1px solid #F0F0F0;
    color: #666;
    position: relative;
    margin-bottom:5px ;
    .itemLabel{
      position:absolute;
      left: 0;
      top: 0;
      z-index: 9;
      background: #ccc;
      opacity:0.5;
      width: 100%;
      height: 100%;
    }
    .itemLabelP{
        position:absolute;
        left: 0;
        top: 0;
        z-index:10;
        opacity:1;
        width: 100%;
        height: 100%;
        opacity: 1;
        font-size:26px;
        font-weight: bold;
        line-height:126px;
        text-align: center;
        color: #FFD303;
        span{
          padding-right:40px
        }
      }
    .total {
        font-weight: bold;
    }
    .msgBox{
      font-size: 14px;
      cursor: pointer;
      &:hover{
        color: #FFD303;
      }
    }
 }
 

 
</style>